import { isNud } from '@/shared/utilities/typing';

/**
 * ユーザー エンティティ
 */
class TemplateNote {
    template_note_id;
    template_name;
    template_content;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.template_note_id = properties.template_note_id;
        this.template_name = properties.template_name;
        this.template_content = properties.template_content;
    }
}

export default TemplateNote;
